import {
  Button,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Heading,
} from '@chakra-ui/react'
import { useState } from 'react'
import { assocPath, get as get_in } from 'lodash/fp'

export const AUTH_STATE = 'AUTH_STATE'

export const LOGGED_OUT = 'LOGGED_OUT'
export const LOGGED_IN = 'LOGGED_IN'
const LOGGING_IN = 'LOGGING_IN'
const LOGGED_OUT_ERROR = 'LOGGED_OUT_ERROR'
export const CHECKING_SESSION = 'CHECKING_SESSION'

export const USERNAME = 'USERNAME'

function PasswordInput(props) {
  let state = props.state
  let set_state = props.set_state
  const [show, setShow] = useState(false)
  const handleClick = () => setShow(!show)

  return (
    <InputGroup size='md'>
      <Input
        type={show ? 'text' : 'password'}
        placeholder='Enter password'
        value={get_in(['ui_login_password'], state)}
        disabled={props.disabled}
        onChange={(e) => {
          set_state(assocPath(['ui_login_password'], e.target.value, state))
        }}
      />
      <InputRightElement width='4.5rem'>
        <Button size='sm' onClick={handleClick}>
          {show ? 'Hide' : 'Show'}
        </Button>
      </InputRightElement>
    </InputGroup>
  )
}

export function logout(state, set_state, initial_state) {
  // run logout optimistically
  return async function () {
    try {
      let s1 = assocPath([AUTH_STATE], LOGGED_OUT, initial_state)
      set_state(s1)
      const res = await fetch(`/api/logout`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
      })
    } catch (err) {
      console.log(err)
    }
  }
}

function login(state, set_state) {
  return async function () {
    try {
      set_state(assocPath([AUTH_STATE], LOGGING_IN, state))
      const res = await fetch(`/api/login`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: state['ui_login_username'],
          password: state['ui_login_password'],
        }),
      })
      const data = await res.json()
      if (data.ok === true) {
        let s1 = assocPath([AUTH_STATE], LOGGED_IN, state)
        let s2 = assocPath([USERNAME], data.user, s1)
        let s3 = assocPath(['ui_login_username'], '', s2)
        let s4 = assocPath(['ui_login_password'], '', s3)
        set_state(s4)
      } else {
        let s1 = assocPath([AUTH_STATE], LOGGED_OUT_ERROR, state)
        set_state(s1)
      }
    } catch (err) {
      let s1 = assocPath([AUTH_STATE], LOGGED_OUT_ERROR, state)
      set_state(s1)
      console.log(err)
    }
  }
}

export function Login(props) {
  let state = props.state
  let set_state = props.set_state
  let disabled = state[AUTH_STATE] === LOGGING_IN
  const [show, setShow] = useState(false)
  const handleClick = () => setShow(!show)
  const msg = disabled
    ? 'Checking credentials'
    : 'Please enter your credentials:'
  return (
    <Stack>
      <Heading>Welcome to lyla designer.</Heading>
      <Heading>{msg}</Heading>
      <Input
        value={get_in(['ui_login_username'], state)}
        onChange={(e) => {
          set_state(assocPath(['ui_login_username'], e.target.value, state))
        }}
        placeholder='Username'
        disabled={disabled}
      />
      <PasswordInput disabled={disabled} {...props} />
      {
        //Check if message failed
        state[AUTH_STATE] === LOGGED_OUT_ERROR ? (
          <div>Credentials are invalid!</div>
        ) : (
          <div></div>
        )
      }
      <Button onClick={login(state, set_state)}>Login</Button>
    </Stack>
  )
}

export function initial_state() {
  return {
    ui_login_username: '',
    ui_login_password: '',
    USERNAME: '',
    AUTH_STATE: CHECKING_SESSION,
  }
}

export async function check_session(state, set_state) {
  try {
    console.log('check=sessiion')
    set_state(assocPath([AUTH_STATE], LOGGING_IN, state))
    const res = await fetch(`/api/check-session`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({}),
    })
    const data = await res.json()
    console.log(data)
    if (data.ok === true) {
      let s1 = assocPath([AUTH_STATE], LOGGED_IN, state)
      let s2 = assocPath([USERNAME], data.user, s1)
      set_state(s2)
    } else {
      let s1 = assocPath([AUTH_STATE], LOGGED_OUT)
      set_state(s1)
    }
  } catch (err) {
    let s1 = assocPath([AUTH_STATE], LOGGED_OUT)
    set_state(s1)
    console.log(err)
  }
}
