import {
  Button,
  Input,
  InputGroup,
  InputRightElement,
  Flex,
  Stack,
  Heading,
} from '@chakra-ui/react'
import Head from 'next/head'
import { useState, useEffect } from 'react'
import { merge, assocPath, get as get_in } from 'lodash/fp'
import dynamic from 'next/dynamic'
import { Suspense } from 'react'
import {
  Login,
  initial_state as login_state,
  LOGGED_IN,
  AUTH_STATE,
  USERNAME,
  check_session,
  logout,
  CHECKING_SESSION,
} from 'components/login'
import styles from '../styles/Home.module.css'

function initial_state() {
  return login_state()
}

function LoggedIn(props) {
  let state = props.state
  let set_state = props.set_state
  const DummyEditor = dynamic(() => import('./editor.js'), {
    suspense: true,
  })

  return (
    <Flex direction={'column'} height={'100vh'} width={'100vw'}>
      <Flex>
        logged in as {state[USERNAME]}
        <Button onClick={logout(state, set_state, initial_state())}>
          Logout
        </Button>
      </Flex>
      <Flex height={'100%'}>
        <Suspense fallback={<div>Loading...</div>}>
          <DummyEditor />
        </Suspense>
      </Flex>
    </Flex>
  )
}


export default function Home1() {
  const [state, set_state] = useState(initial_state())
  console.log(state)
  useEffect(() => {
    check_session(state, set_state)
  }, [])
  if (state[AUTH_STATE] === CHECKING_SESSION) {
    return <div>Loading ...</div>
  } else if (state[AUTH_STATE] === LOGGED_IN) {
    return <LoggedIn state={state} set_state={set_state} />
  } else {
    return (
      <div>
        <Flex
          p={'15px'}
          fontSize={{ base: '0.5em', md: '1em' }}
          direction={{ base: 'column', md: 'row' }}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Head>
            <title>Lyla Designer</title>
            <meta
              name='description'
              content='Make your own 3D printable designs'
            />
            <link rel='icon' href='/favicon.ico' />
          </Head>
          <Login state={state} set_state={set_state} />
        </Flex>
      </div>
    )
  }
}
